import http from "../http-common";

const upload = (files,formd, onUploadProgress) => {
    let formData = new FormData();
    console.log(files);
    console.log(formd);
    formData.append('bit', formd.bit);
    formData.append('channel', formd.channel);
    formData.append('rate', formd.rate);

    for (let i = 0; i < files.length; i++) {
        formData.append('audiofile', files[i])
    }
    //formData.append("audiofile", file);

    return http.post("/upload", formData, {
        responseType: 'arraybuffer',
        headers: {
            "Content-Type": "multipart/form-data",
        },
        onUploadProgress,
    });
};

const getFiles = () => {
  return http.get("/getfileinfo");
};

const downloadFile = (sid) => {
  return http.get(`/downloadfile/${sid}`,{
    responseType: 'arraybuffer',
        headers: {
            "Content-Type": "multipart/form-data",
        },
  });
};

export default {
  upload,
  getFiles,
  downloadFile,
};
