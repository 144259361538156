import axios from "axios";

export default axios.create({
  //use localhost when running locally
  //baseURL: "http://localhost:3100/api",
  baseURL: "https://convert.dkyadavlab.com/api",
  withCredentials: true,
  headers: {
    "Content-type": "application/json",
   //"Content-type": "application/x-www-form-urlencoded"
  }
});
