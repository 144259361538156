import React, { useState, useEffect } from "react";
import UploadService from "../services/FileUploadService";

const UploadFiles = () => {

    const [selectedFiles, setSelectedFiles] = useState(undefined);
    const [currentFile, setCurrentFile] = useState(undefined);
    const [progress, setProgress] = useState(0);
    const [message, setMessage] = useState("");
    const [alert, setAlert] = useState("alert-light");

    const [convertTo, setConvertTo] = useState('wav');
    const [sampleRate, setSampleRate] = useState('11025');
    const [sampleSize, setSampleSize] = useState(16);
    const [channels, setChannels] = useState(1);

    const [fileInfos, setFileInfos] = useState([]);

    const [visitor, setVisitor] = useState(0);
    const [totalfiles, settotalfiles] = useState(0);
    const [totaldata, settotaldata] = useState(0);

    const selectFile = (event) => {
      setSelectedFiles(event.target.files);
    };
    const selectconvertTo = (event) => {
      setConvertTo(event.target.value);
    };
    const selectsampleRate = (event) => {
      console.log(event.target.value);
      setSampleRate(event.target.value);
    };
    const selectsampleSize = (event) => {
      console.log(event.target.value);
      setSampleSize(Number(event.target.value));
    };
    const selectchannels = (event) => {
      setChannels(Number(event.target.value));
    };


    const upload = () => {
        let currentFile = selectedFiles;

        setProgress(0);
        setCurrentFile(currentFile);
        const formd = {};
        formd.rate=sampleRate;
        formd.channel=channels;
        formd.bit=sampleSize;

        UploadService.upload(currentFile,formd, (event) => {
            //console.log(`event.loaded:${event.loaded} event.total:${event.total}`);
            setProgress(Math.round((100 * event.loaded) / event.total));
            if(event.loaded === event.total){
              setAlert('alert-warning');
              setMessage((<>File sucessfully uploaded! <br/><strong>Download dialog will appear after background file conversion.</strong> Please wait</>));
            }
        })
        .then((response) => {
            //setMessage(response.data.message);
            console.log(response)
            setAlert('alert-success');
            setMessage((<>File sucessfully generater for download! <br/><strong>It will be available for 15 minutes to download</strong> Please use below list of files to download it within 15 minutes</>));
            const disposition = response.request.getResponseHeader('Content-Disposition')
            var fileName = "";
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            var matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
                fileName = matches[1].replace(/['"]/g, '');
            }
            let blob = new Blob([response.data], { type: 'application/zip' })

            const downloadUrl = URL.createObjectURL(blob)
            let a = document.createElement("a"); 
            a.href = downloadUrl;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
          
            return UploadService.getFiles();
        })
        .then((files) => {
          console.log('response in file')
          setFileInfos(files.data.retArrfiles);
        })
        .catch((err) => {
            setProgress(0);
            setAlert('alert-danger');
            
            const retMsg = JSON.parse(String.fromCharCode.apply(null, new Uint8Array(err.response.data)));
            if(retMsg.message !== ''){
              //var boldElement = React.createElement('b', null, "Text (as a string)");
              //const htmlMessage = (<div>Could not upload the file!<br/> <strong>Reason: </strong>{retMsg.message}</div>);
              setMessage((<div>Could not upload the file!<br/> <strong>Reason: </strong>{retMsg.message}</div>));
            }else{
              setMessage("Could not upload the file. Reason: "+err);
            }

            setCurrentFile(undefined);
        });

        setSelectedFiles(undefined);
    };
   


    const downloadFile = (sid) => {
      console.log(sid);
      UploadService.downloadFile(sid).then((response) => {
          console.log(response);
          const disposition = response.request.getResponseHeader('Content-Disposition')
            var fileName = "";
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            var matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
                fileName = matches[1].replace(/['"]/g, '');
            }
            let blob = new Blob([response.data], { type: 'application/zip' })

            const downloadUrl = URL.createObjectURL(blob)
            let a = document.createElement("a"); 
            a.href = downloadUrl;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
          //setFileInfos(response.data);
      });
    }

    useEffect(() => {
        UploadService.getFiles().then((response) => {
            console.log(response.data);
            setFileInfos(response.data.retArrfiles);
            setVisitor(response.data.visitors);
            settotalfiles(response.data.totalFiles);
            settotaldata(response.data.totalSize);
        });
    }, []);
  
    return (
        <div>
          {currentFile && (
            <div className="progress">
              <div
                className="progress-bar progress-bar-info progress-bar-striped"
                role="progressbar"
                aria-valuenow={progress}
                aria-valuemin="0"
                aria-valuemax="100"
                style={{ width: progress + "%" }}
              >
                {progress}%
              </div>
            </div>
          )}

          <div>
          <label className="btn btn-default col-12">
            <input type="file" onChange={selectFile} multiple className="fileUpload" />
          </label>
          </div>

          <div>
            <form name="convertFormats" id="convertFormats">
              <div className="row">
              <div className="col-3">
                  <label htmlFor="convertTo" className="form-label"><strong>Convert To</strong></label>
                  <select id="convertTo" className="form-select">
                    <option value="wav">Wave Format</option>
                  </select>
                </div>

                <div className="col-3">
                  <label htmlFor="sampleRate" className="form-label"><strong>Sample Rate</strong></label>
                  <select id="sampleRate" className="form-select" onChange={selectsampleRate} value={11025}>
                    <option value="11025">11025 Hz</option>
                    <option value="16000">16000 Hz</option>
                    <option value="22050">22050 Hz</option>
                    <option value="44100">44100 Hz</option>
                  </select>
                </div>
                <div className="col-3">
                  <label htmlFor="sampleSize" className="form-label"><strong>Sample Size</strong></label>
                  <select id="sampleSize" className="form-select" onChange={selectsampleSize} value={16}>
                    <option value="8">8 bit</option>
                    <option value="16">16 bit</option>
                  </select>
                </div>
                <div className="col-3">
                  <label htmlFor="channels" className="form-label"><strong>Channels</strong></label>
                  <select id="channels" className="form-select" onChange={selectchannels} value={1}>
                    <option value="1">1 Mono</option>
                    <option value="2">2 Stereo</option>
                  </select>
                </div>
              </div>
            </form>
          </div>
          
          <div className="row justify-content-center my-5">
            <button
              className="btn btn-success col-6"
              disabled={!selectedFiles}
              onClick={upload}
            >
              Convert
            </button>
          </div>
    
          <div className={`alert ${alert}`} role="alert">
            {message}
          </div>
    
          <div className="card">
            <div className="card-header">List of Files</div>
            <ul className="list-group list-group-flush">
              {fileInfos && fileInfos.length > 0 && 
                fileInfos.map((file, index) => (
                  <li className="list-group-item" key={index}>
                    <div>
                      File Name <span className="badge bg-secondary">{file.name}</span>&nbsp;
                      Size<span className="badge bg-secondary">{file.size} MB</span>&nbsp;
                      DateTime<span className="badge bg-secondary">{file.dt}</span>&nbsp;
                      <button
                        className="btn btn-outline-primary col-3 btn-sm justify-content-center"
                        onClick={()=>downloadFile(file.id)}
                        >
                          Download Combined File
                      </button>
                    </div>
                  </li>
                ))}
            </ul>
          </div>
               
          {/* <div className="row justify-content-end">
            <div className="col-3">
              Total unique visitors 
              <span className="badge bg-secondary">
                {visitor}
              </span>
              </div>
          </div> */}

          <figure className="text-end">
            <blockquote className="blockquote">
              <p>Total unique visitors </p>
            </blockquote>
            <figcaption className="blockquote-footer">
              {visitor}
            </figcaption>
          </figure>

          <figure className="text-end">
            <blockquote className="blockquote">
              <p>Total files converted </p>
            </blockquote>
            <figcaption className="blockquote-footer">
              {totalfiles}
            </figcaption>
          </figure>

          <figure className="text-end">
            <blockquote className="blockquote">
              <p>Total data converted </p>
            </blockquote>
            <figcaption className="blockquote-footer">
              {totaldata} MB
            </figcaption>
          </figure>

        </div>
      );
};

export default UploadFiles;
