import logo from './logo.svg';
import './App.css';

import "bootstrap/dist/css/bootstrap.min.css";

import FileUpload from "./components/FileUpload";

function Main() {
  return (
    
    <span>
      <div className="my-5">
        <h3 align="center">Audio File Converter</h3>
        <h5 align="center">Choose or Drag/Drop file(s) [<small>Format allowed: wave, mp3 and zip</small>]</h5>
      </div>
      
      <FileUpload />
      
    </span>
  );
}

export default Main;
