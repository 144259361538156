//import logo from './logo.svg';
import './App.css';

import "bootstrap/dist/css/bootstrap.min.css";
import {Link, BrowserRouter as Router,Route} from 'react-router-dom';

import FileUpload from "./components/FileUpload";
import PrivacyPolicy from "./privacy";
import Main from "./Main";
//import ReactGA from 'react-ga';

// const TRACKING_ID = "UA-210932034-1"; // G-LCX8PFBYWQ
// ReactGA.initialize(TRACKING_ID);
// ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
  return (
    
    <div className="container" >
      <Router>
        <Route path="/" exact>
          <Main/>
        </Route>
        <Route path="/privacy">
          <PrivacyPolicy/>
        </Route>
        {/* <Link to='/privacy'>Privacy Policy Check</Link> */}
        <footer>
          <Link to='/privacy'>Privacy Policy Check</Link>
        </footer>
      </Router>
    </div>
  );
}

export default App;
